import React, { Component } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import {facreativecommons} from '@fortawesome/react-fontawesome'
//import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { faDollarSign, faLink } from "@fortawesome/free-solid-svg-icons";
import { faCreativeCommons } from '@fortawesome/free-brands-svg-icons';
//import FileUpload from '../Backups/FileUpload';
// Loading Assets (SubComponents & CSS)
import SearchBar from "./SearchBar";
import Editor from "../Backups/Editor";
import "../css/Selector.css";
import ColorSelector from "../components/ColorSelector";
import FactoryItemCard from "./factoryItemCard/factoryItemCard";
import bones from "../library/bones.json";
import { HvacRounded } from "@mui/icons-material";

class Selector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorSelected: false,
            pose: null,
            search: '',
            libraries: {},
            userIP: null,
            apiEndpoint: 'https://czbackend.herokuapp.com/api/eb_m',  // default endpoint
            selectedBody: null,
        };
       
        this.applyPose = this.applyPose.bind(this);
       
    }


    updateSearchValue = search => {
        this.setState({ search });
    };

    async fetchAndUpdateLibrariesold() {
        const categories = [
            'head', 'pad', 'hand', 'arm', 'body', 'chest', 'foot', 'leg', 'pose', 'stand', 'env', 'skybox',
        ];

        let libraries = {};

        for (const category of categories) {
            libraries[category] = await this.fetchLibraryData(category);
        }

        this.setState({ libraries });
    }

    async fetchAndUpdateLibraries2() {
        // Clear the libraries
        this.setState({ libraries: {} }, async () => {
            const categories = [
                'head', 'pad', 'hand', 'arm', 'body', 'chest', 'foot', 'leg', 'pose', 'stand', 'env', 'podium', 'prop01', 'prop02', 'prop03', 'skybox',
            ];

            let libraries = {};

            for (const category of categories) {
                libraries[category] = await this.fetchLibraryData(category);
            }

            this.setState({ libraries });
        });
    }

    async fetchAndUpdateLibraries(newEndpoint) {
        try {
            const categories = [
                'head', 'pad', 'hand', 'arm', 'body', 'chest', 'waist', 'back', 'foot', 'leg', 'pose', 'stand', 'env', 'podium', 'prop01', 'prop02', 'prop03', 'skybox',
            ];

            let libraries = {};

            for (const category of categories) {
                libraries[category] = await this.fetchLibraryData(category, newEndpoint);
            }

            this.setState({ libraries, apiEndpoint: newEndpoint });
        } catch (err) {
            console.error('Error updating libraries:', err);
        }
    }


    poseRandomizer = async () => {
        const { libraries } = this.state;
        try {
            // Randomize the pose selection
            const poseLibrary = libraries['pose'];
            const randomPoseIndex = Math.floor(Math.random() * poseLibrary.length);
            const randomPoseFile = poseLibrary[randomPoseIndex].file;

            // Fetch the pose data using the random pose file
            const poseRes = await axios.get(`https://citizenzero.s3.us-west-1.amazonaws.com/models/poses/${randomPoseFile}.json`);
            this.setState({ pose: poseRes.data });
            window.loadDefaultMeshes(bones, poseRes.data);
        } catch (err) {
            console.error('Error fetching pose:', err);
        }
    }


    async componentDidMount() {
        //this.fetchIP();

        // Fetch all library data
        const categories = [
            'head', 'pad', 'hand', 'arm', 'body', 'chest', 'waist', 'back', 'foot', 'leg', 'pose', 'stand', 'env', 'podium', 'prop01', 'prop02', 'prop03', 'skybox'
        ];

        let libraries = {};

        for (const category of categories) {
            libraries[category] = await this.fetchLibraryData(category);
        }

        // Load the base model with defaultMeshes and random pose
        try {
            // Randomize the pose selection
            const poseLibrary = libraries['pose'];
            const randomPoseIndex = Math.floor(Math.random() * poseLibrary.length);
            const randomPoseFile = poseLibrary[randomPoseIndex].file;

            // Fetch the pose data using the random pose file
            //const poseRes = await axios.get(`https://citizenzero.s3.us-west-1.amazonaws.com/models/poses/${randomPoseFile}.json`);
            const poseRes = await axios.get(`https://citizenzero.s3.us-west-1.amazonaws.com/models/poses/action.json`);
            this.setState({ pose: poseRes.data });
            window.loadDefaultMeshes(bones, poseRes.data);

        } catch (err) {
            console.error('Error fetching pose:', err);
        }

        this.setState({ libraries });
    }

    async fetchLibraryData(category) {
        try {
            let endpoint;

            switch (category) {
                case 'pose':
                    endpoint = 'poses';
                    break;
                case 'head':
                    endpoint = 'head';
                    break;
                case 'pad':
                    endpoint = 'pads';
                    break;
                case 'waist':
                    endpoint = 'waist';
                    break;
                case 'back':
                    endpoint = 'back';
                    break;
                case 'hand':
                    endpoint = 'hands';
                    break;
                case 'arm':
                    endpoint = 'arms';
                    break;
                case 'body':
                    endpoint = 'body';
                    break;
                case 'chest':
                    endpoint = 'chest';
                    break;
                case 'foot':
                    endpoint = 'feet';
                    break;
                case 'leg':
                    endpoint = 'legs';
                    break;
                case 'stand':
                    endpoint = 'stand';
                    break;
                case 'env':
                    endpoint = 'env';
                    break;
                case 'podium':
                    endpoint = 'podium';
                    break;
                case 'prop01':
                    endpoint = 'prop01';
                    break;
                case 'prop02':
                    endpoint = 'prop01';
                    break;
                case 'prop03':
                    endpoint = 'prop01';
                    break;
                case 'skybox':
                    endpoint = 'skybox';
                    break;
                default:
                    throw new Error(`Unknown category: ${category}`);
            }
            //console.log(this.state.apiEndpoint);
            const url = (endpoint === 'body' || endpoint === 'skybox' || endpoint === 'prop01' || endpoint === 'podium' || endpoint === 'env' || endpoint === 'stand')
                ? `https://czbackend.herokuapp.com/api/elements/${endpoint}`
                : `${this.state.apiEndpoint}/${endpoint}`;

            const res = await axios.get(url);
            if (Array.isArray(res.data)) {
                res.data.sort((a, b) => {
                    // Convert to lowercase for case-insensitive comparison
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    // 'none' should come first
                    if (nameA === 'none') return -1;
                    if (nameB === 'none') return 1;

                    // Otherwise, sort alphabetically
                    return nameA.localeCompare(nameB);
                });
            }
            return res.data;


        } catch (err) {
            console.error(`Error fetching library data for ${category}:`, err);
            return [];
        }
    }





    async componentDidMountusedtouse() {
        this.fetchIP();

        // Fetch all library data
        const categories = [
            'head', 'pad', 'hand', 'arm', 'body', 'chest', 'foot', 'leg', 'pose', 'stand', 'env', 'skybox',
        ];

        let libraries = {};

        for (const category of categories) {
            libraries[category] = await this.fetchLibraryData(category);
        }

        // Load the base model with defaultMeshes and random pose
        try {
            // Randomize the pose selection
            const poseLibrary = libraries['pose'];
            const randomPoseIndex = Math.floor(Math.random() * poseLibrary.length);
            const randomPoseFile = poseLibrary[randomPoseIndex].file;

            // Fetch the pose data using the random pose file
            const poseRes = await axios.get(`https://citizenzero.s3.us-west-1.amazonaws.com/models/poses/${randomPoseFile}.json`);
            this.setState({ pose: poseRes.data });
            window.loadDefaultMeshes(bones, poseRes.data);

        } catch (err) {
            console.error('Error fetching pose:', err);
        }

        this.setState({ libraries });
    }


    async componentDidMountold() {
        this.fetchIP();
        // Load the base model with defaultMeshes and defaultPose
        try {
            const poseRes = await axios.get('https://citizenzero.s3.us-west-1.amazonaws.com/models/poses/The_Night.json');
            this.setState({ pose: poseRes.data });
            window.loadDefaultMeshes(bones, poseRes.data);
        } catch (err) {
            console.error('Error fetching pose:', err);
        }

        // Fetch all library data
        const categories = [
            'head', 'pad', 'hand', 'arm', 'body', 'chest', 'foot', 'leg', 'pose', 'stand', 'env', 'skybox',
        ];

        let libraries = {};

        for (const category of categories) {
            libraries[category] = await this.fetchLibraryData(category);
        }

        this.setState({ libraries });
    }

    async componentDidUpdate(prevProps) {
        // If the `refresh` prop has changed, fetch the libraries again
        if (this.props.refresh !== prevProps.refresh) {
            await this.fetchAndUpdateLibraries();
        }
    }


    async fetchLibraryDataold(category) {
        try {
            let url;
            if (category === 'pose') {
                url = 'https://czbackend.azurewebsites.net/api/poses/';
            } else {
                url = `https://czbackend.azurewebsites.net/api/library/${category}`;
            }

            const res = await axios.get(url);
            return res.data;

        } catch (err) {
            console.error(`Error fetching library data for ${category}:`, err);
            return [];
        }
    }


    applyPose(file) {
        let poseData;
        //Ajax in react
        //axios.get("./models/poses/" + file + ".json").then(res => {
        axios.get("https://citizenzero.s3.us-west-1.amazonaws.com/models/poses/" + file + ".json").then(res => {
            poseData = res.data;
            this.setState({ pose: poseData });
            window.loadPose(poseData, bones);

            // WE NEED TO SET customPoseData to NULL every time we run this. customPoseData is stored in MainStage.js

        });
    }

    static RenderPremium(item) {
        if (item.premium) {
            return (
                <div className="abs premium">
                    Locked
                    <FontAwesomeIcon
                        className="abs centered white big-icon"
                    //icon={faDollarSign}
                    />
                </div>
            );
        }
    }

    static RenderLinkORIG(item) {
        if (item.link) {
            return (
                <a className="abs link" href={item.link} target="_blank">
                    Link
                    <FontAwesomeIcon
                        className="abs centered white icon"
                    //icon={faLink}
                    />
                </a>
            );
        }
    }

    static RenderLink(item) {
        if (item.link) {
            return (
                <a className="abs link"
                    onClick={(e) => {
                        e.stopPropagation();
                        window.open(item.link, '_blank');
                    }}>
                    Link
                </a>
            );
        }
    }

    static RenderLicenseORIG(item) {
        if (item.license) {
            return (
                <a className="abs license" href={item.license} target="_blank">
                    <FontAwesomeIcon
                        className="abs centered white licenseIcon"
                        icon={faCreativeCommons}
                    />
                </a>
            );
        }
    }

    static RenderLicense(item) {
        if (item.license) {
            return (
                <a className="abs license"
                    onClick={(e) => {
                        e.stopPropagation();
                        window.open(item.license, '_blank');
                    }}>
                    <FontAwesomeIcon
                        className="abs centered white licenseIcon"
                        icon={faCreativeCommons}
                    />
                </a>
            );
        }
    }

    static RenderAI(item) {
        if (item.AI) {
            return (
                <a className="abs AI"
                //href={item.license} target="_blank"
                >
                    AI
                </a>
            );
        }
    }

    render() {
        const { onRefreshLibraries } = this.props;
        const category = this.props.currentCategory;
        const isLeft = this.props.isLeft;
        let library;
        let sideIndicator;
        let meshType;

        if (this.state.libraries.hasOwnProperty(category)) {
            library = this.state.libraries[category];
        } else {
            library = [];
        }

        switch (category) {
            case "head":
                //library = headElements;
                sideIndicator = false;
                meshType = "Head";
                break;
            case "waist":
                //library = waistElements;
                sideIndicator = false;
                meshType = "Waist";
                break;
            case "back":
                //library = backElements;
                sideIndicator = false;
                meshType = "Back";
                break;
            case "pad":
                //library = padElements;
                sideIndicator = true;
                meshType = isLeft ? "PadL" : "PadR";
                break;
            case "hand":
                //library = handElements;
                sideIndicator = true;
                meshType = isLeft ? "HandL" : "HandR";
                break;
            case "arm":
                //library = armElements;
                sideIndicator = true;
                meshType = isLeft ? "ArmL" : "ArmR";
                break;
            case "body":
                //library = bodyElements;
                sideIndicator = false;
                meshType = "Body";
                break;
            case "chest":
                //library = chestElements;
                sideIndicator = false;
                meshType = "Chest";
                break;
            case "foot":
                //library = footElements;
                sideIndicator = true;
                meshType = isLeft ? "FootL" : "FootR";
                break;
            case "leg":
                //library = legElements;
                sideIndicator = true;
                meshType = isLeft ? "LegL" : "LegR";
                break;
            case "pose":
                //library = poseElements;
                sideIndicator = false;
                break;
            case "stand":
                //library = standElements;
                sideIndicator = false;
                break;
            case "env":
                //library = envElements;
                sideIndicator = false;
                break;
            case "podium":
                sideIndicator = false;
                break;
            case "prop01":
                sideIndicator = false;
                break;
            case "prop02":
                sideIndicator = false;
                break;
            case "prop03":
                sideIndicator = false;
                break;
            case "skybox":
                sideIndicator = false;
                break;
            default:
                //library = headElements;
                sideIndicator = false;
        }

        let filteredLibrary = library.filter(
            (element) => {
                return element.name.toLowerCase().indexOf(this.state.search) !== -1;
            }
        );

        //JSX element to display the HTML
        //const elementDiv = [];
        const elementDiv = filteredLibrary.map((item, index) => (
<FactoryItemCard
    key={index}
    saleItem={item}
    category={category}
    isLeft={isLeft}
    bones={bones}
    applyPose={this.applyPose}
    updateMeshes={this.props.updateMeshes}
    loadedMeshes={this.props.loadedMeshes}
    updateLoading={this.props.updateLoading}
    customPoseData={this.props.customPoseData}
    updatePopupMessage={this.props.updatePopupMessage}
    updatePopup={this.props.updatePopup}
    fetchAndUpdateLibraries={this.fetchAndUpdateLibraries}
    selectedBody={this.state.selectedBody}
    apiEndpoint={this.state.apiEndpoint}
    pose={this.state.pose}
  />
));


        /* for (let i = 0; i < filteredLibrary.length; i++) {
            elementDiv.push(
                <div className="elContainer">
                <div
                    className={filteredLibrary[i].premium ? "el premium2" : "el"}
                    key={i}
                    title={filteredLibrary[i].description}
                    onClick={() => {
                        let meshType;
                        switch (category) {
                            case "body":
                                meshType = "Body";
                                break;
                            case "chest":
                                meshType = "Chest";
                                break;
                            case "head":
                                meshType = "Head";
                                break;
                            case "pad":
                                meshType = isLeft ? "PadL" : "PadR";
                                break;
                            case "hand":
                                meshType = isLeft ? "HandL" : "HandR";
                                break;
                            case "arm":
                                meshType = isLeft ? "ArmL" : "ArmR";
                                break;
                            case "foot":
                                meshType = isLeft ? "FootL" : "FootR";
                                break;
                            case "leg":
                                meshType = isLeft ? "LegL" : "LegR";
                                break;
                            case "podium":
                                meshType = "Podium";
                                break;
                            case "prop01":
                                meshType = "Prop01";
                                break;
                            case "prop02":
                                meshType = "Prop02";
                                break;
                            case "prop03":
                                meshType = "Prop03";
                                break;
                            case "skybox":
                                meshType = "skybox";
                                break;
                            default:
                                meshType = undefined;
                        }

                        if (filteredLibrary[i].premium) {
                            this.props.updatePopupMessage(
                                "Item is locked and might be available at a later time. No promises though."
                            );
                            this.props.updatePopup(true);
                        } else {
                            if (category === "pose") {
                                this.applyPose(filteredLibrary[i].file);
                            } else if (category === "stand") {
                                window.changeStand(filteredLibrary[i].file);
                            } else if (category === "podium") {
                                window.changePodium(filteredLibrary[i].file);
                            } else if (category === "body") {
                                window.removeAllArmor();
                                let newEndpoint;
                                let newPose;
                                console.log("LOAD SHIT");
                                switch (filteredLibrary[i].bid) {
                                    case 'earthborn_m':
                                        newEndpoint = 'https://czbackend.herokuapp.com/api/eb_m';
                                        newPose = 'action';
                                        break;
                                    case 'earthborn_f':
                                        newEndpoint = 'https://czbackend.herokuapp.com/api/eb_f';
                                        newPose = 'ebf';
                                        break;
                                    case 'dynalith':
                                        newEndpoint = 'https://czbackend.herokuapp.com/api/dynalith';
                                        newPose = 'dyna2';
                                        break;
                                    default:
                                        newEndpoint = 'https://czbackend.herokuapp.com/api/eb_m';
                                        newPose = 'Default';
                                }

                                this.setState({ selectedBody: filteredLibrary[i].bid, apiEndpoint: newEndpoint });
                                this.fetchAndUpdateLibraries(newEndpoint);
                                this.props.updateLoading(true);

                                window.changeMesh(
                                    category,
                                    filteredLibrary[i],
                                    isLeft,
                                    bones,
                                    this.state.pose,
                                    this.props.customPoseData
                                );

                                let loadedMeshes = this.props.loadedMeshes;
                                loadedMeshes["Body"] = filteredLibrary[i].file;
                                this.props.updateMeshes(loadedMeshes);

                                setTimeout(() => {
                                    this.fetchAndUpdateLibraries(newEndpoint);
                                    this.applyPose(newPose);
                                }, 1000);

                            } else if (category === "env") {
                                window.changeEnv(filteredLibrary[i].file);
                            } else if (category === "prop01") {
                                window.changeProp(
                                    filteredLibrary[i].file,
                                    meshType
                                );
                            } else if (category === "prop02") {
                                window.changeProp(
                                    filteredLibrary[i].file,
                                    meshType
                                );
                            } else if (category === "prop03") {
                                window.changeProp(
                                    filteredLibrary[i].file,
                                    meshType
                                );
                            } else if (category === "skybox") {
                                window.changeSkybox(
                                    filteredLibrary[i].file,
                                );
                            } else {
                                this.props.updateLoading(true);
                                window.changeMesh(
                                    category,
                                    filteredLibrary[i],
                                    isLeft,
                                    bones,
                                    this.state.pose,
                                    this.props.customPoseData
                                );
                                let loadedMeshes = this.props.loadedMeshes;
                                loadedMeshes[meshType] = filteredLibrary[i].file;
                                this.props.updateMeshes(loadedMeshes);

                            }
                        }
                    }}
                >
                    
                    <div className={category === 'skybox' ? 'skyimg' : 'img'}>
                        <img
                            src={
                                category === 'skybox'
                                    ? "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/" + filteredLibrary[i].file + "/" + "thumb.png"
                                    : "https://citizenzero.s3.us-west-1.amazonaws.com/thumbs/" + category + "/" + filteredLibrary[i].img
                            }
                            alt={filteredLibrary[i].img}
                        />
                    </div>


                    <div className={`unselectable ${category === 'skybox' ? 'skyname' : 'el-name'}`}>
                        {filteredLibrary[i].name}
                    </div>
                    
                </div>
                </div>
            );
        }
        */
       //add above div div
        //{Selector.RenderPremium(filteredLibrary[i])}
        //{Selector.RenderLink(filteredLibrary[i])}
        //{Selector.RenderLicense(filteredLibrary[i])}
        //{Selector.RenderAI(filteredLibrary[i])}

        /* maybe one day
        elementDiv.push(
<div
  className="el"
  key="add"
  onClick={this.state.userIP && this.handleClick}
>
  <div className="img">
    <img src="img/library/plus.svg" alt="plus sign" />
  </div>
  <div className="unselectable el-name">Upload</div>
</div>

); */


        const buttons = (
            <div className="switch">
                <div
                    className={
                        "unselectable left side L " +
                        (isLeft ? "side-selected" : "")
                    }
                    onClick={() => {
                        this.props.updateLeft(true);
                        let meshType;
                        switch (category) {
                            case "head":
                                meshType = "Head";
                                break;
                            case "pad":
                                meshType = "PadL";
                                break;
                            case "hand":
                                meshType = "HandL";
                                break;
                            case "arm":
                                meshType = "ArmL";
                                break;
                            case "body":
                                meshType = "Body";
                                break;
                            case "chest":
                                meshType = "Chest";
                                break;
                            case "waist":
                                meshType = "Waist";
                                break;
                            case "back":
                                meshType = "Back";
                                break;
                            case "foot":
                                meshType = "FootL";
                                break;
                            case "leg":
                                meshType = "LegL";
                                break;
                            case "podium":
                                meshType = "Podium";
                                break;
                            case "prop01":
                                meshType = "Prop01";
                                break;
                            case "prop02":
                                meshType = "Prop02";
                                break;
                            case "prop03":
                                meshType = "Prop03";
                                break;
                            case "skybox":
                                meshType = "skybox";
                                break;
                            default:
                                meshType = undefined;
                        }
                        if (meshType) {
                            window.selectedMesh(meshType);
                        }
                    }}
                >
                    Left
                </div>
                <div
                    className={
                        "unselectable right side R " +
                        (isLeft ? "" : "side-selected")
                    }
                    onClick={() => {
                        this.props.updateLeft(false);
                        let meshType;
                        switch (category) {
                            case "head":
                                meshType = "Head";
                                break;
                            case "waist":
                                meshType = "Waist";
                                break;
                            case "back":
                                meshType = "Back";
                                break;
                            case "pad":
                                meshType = "PadR";
                                break;
                            case "hand":
                                meshType = "HandR";
                                break;
                            case "arm":
                                meshType = "ArmR";
                                break;
                            case "body":
                                meshType = "Body";
                                break;
                            case "chest":
                                meshType = "Chest";
                                break;
                            case "foot":
                                meshType = "FootR";
                                break;
                            case "leg":
                                meshType = "LegR";
                                break;
                            case "podium":
                                meshType = "Podium";
                                break;
                            case "prop01":
                                meshType = "Prop01";
                                break;
                            case "prop02":
                                meshType = "Prop02";
                                break;
                            case "prop03":
                                meshType = "Prop03";
                                break;
                            case "skybox":
                                meshType = "skybox";
                                break;
                            default:
                                meshType = undefined;
                        }
                        if (meshType) {
                            window.selectedMesh(meshType);
                        }
                    }}
                >
                    Right
                </div>
            </div>
        );

        const editorButtons = (
            <div className="switch">
                <div
                    className={
                        "unselectable left side L " +
                        (this.state.editorSelected ? "" : "side-selected")
                    }
                    onClick={() => {
                        this.setState({ editorSelected: false });
                    }}
                >
                    Poses
                </div>
                <div
                    className={
                        "unselectable right side R " +
                        (this.state.editorSelected ? "side-selected" : "")
                    }
                    onClick={() => {
                        this.setState({ editorSelected: true });
                    }}
                >
                    Editor
                </div>
            </div>
        );

        return (
            <div>
                <div className=" top right right-side">
                    <div className="box">
                    <ColorSelector />
                        <SearchBar
                            updateSearchValue={this.updateSearchValue}
                            search={this.state.search}
                        />
                        {sideIndicator ? buttons : ""}
                        {category === "pose" && this.props.editor ? editorButtons : ""}
                        <div
                            className={
                                "left " +
                                (category === "pose" && this.state.editorSelected
                                    ? " selector"
                                    : " selector") +
                                (sideIndicator ||
                                    (category === "pose" && this.props.editor)
                                    ? " selector-full"
                                    : " selector")
                            }
                        >
                            {category === "pose" &&
                                this.state.editorSelected &&
                                this.props.editor ? (
                                <Editor />
                            ) : (
                                <div className="selector-no-padding">
                                    
                                    {elementDiv}
                                    
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Selector;
