import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import './showcase.css';
import { EffectFade, Navigation, Autoplay } from 'swiper/modules';

function Showcase({ games }) {
  return (
    <Swiper
      effect="fade"
      navigation={true}
      loop={true}
      autoplay={{
        delay: 6000,
        disableOnInteraction: true,
      }}
      modules={[EffectFade, Navigation, Autoplay]}
      className="showcase-swiper"
    >
      {games.map((game) => (
        <SwiperSlide key={game._id.toString()}>
          <div className="showcase-slide">
          <div className="showcase-details">
              <h2>{game.title}</h2>
              <p>{game.description}</p>
              <p className="showcase-price">{game.price}</p>
              <a className="orderBtn" href="#">
                EQUIP NOW
              </a>
            </div>
            <div className="showcase-image">
              <img src={game.img} alt={game.title} />
            </div>
            
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Showcase;
