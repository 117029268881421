import React, { useRef, useState, useEffect } from "react";
import './coinCardSCSS.scss';

function CoinCard({ coin, activeCard, setActiveCard }) {
  const cardRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [tiltStyle, setTiltStyle] = useState({});
  const styleTag = useRef(null);

  useEffect(() => {
    if (cardRef.current && activeCard === coin.title) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [activeCard, coin.title]);

  const handleMouseMove = (e) => {
    const card = cardRef.current;
    const cardRect = card.getBoundingClientRect();

    const centerX = cardRect.left + cardRect.width / 2;
    const centerY = cardRect.top + cardRect.height / 2;

    const mouseX = e.clientX - centerX;
    const mouseY = e.clientY - centerY;

    // Calculate tilt angles
    const rotateX = (mouseY / cardRect.height) * 30; // Adjust this value for more/less tilt
    const rotateY = -(mouseX / cardRect.width) * 30; // Adjust this value for more/less tilt

    setTiltStyle({
      transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
    });

    // Holographic effect based on mouse position
    const px = Math.abs(Math.floor((100 / cardRect.width) * mouseX) - 100);
    const py = Math.abs(Math.floor((100 / cardRect.height) * mouseY) - 100);
    const lp = 0 + (px - 50) / 1.6;
    const tp = 0 + (py - 50) / 1.6;
    const px_spark = 50 + (px - 50) / 7;
    const py_spark = 50 + (py - 50) / 7;
    const p_opc = 20 + Math.abs((50 - px) + (50 - py)) * 1.5;

    // Set dynamic styles for :before and :after pseudo-elements 
    const styleContent = `
      .coinCard:hover:before {
        background-position: ${lp}% ${tp}%;
      }
      .coinCard:hover:after {
        background-position: ${px_spark}% ${py_spark}%;
        opacity: ${p_opc / 100};
      }
      
    `;
    styleTag.current.innerHTML = styleContent;
  };

  const handleMouseLeave = () => {
    setTiltStyle({
      transform: `rotateX(0deg) rotateY(0deg)`,
      transition: 'transform 0.5s ease',
    });

    // Clear the styles when mouse leaves
    styleTag.current.innerHTML = "";
  };

  return (
    <>
      <style ref={styleTag}></style> {/* This dynamically updates styles for :before and :after */}
      <div className="coinContainer">
        <div
          className={`coinCard ${isActive ? "active" : ""}`}
          ref={cardRef}
          onClick={() => setActiveCard(coin.title)}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          style={tiltStyle}
        >
          <span className="coinDescription">{coin.description}</span>
          <span className="coinTitle">{coin.title}</span>
          <img src={coin.img} className="coinImg" alt={coin.title} />
          <span className="coinPrice">${coin.price.toFixed(2)}</span>
          
        </div>
      </div>
    </>
  );
}

export default CoinCard;
