// src/components/ColorSelector.js
import React, { useState, useContext } from "react";
import '../css/ColorSelector.css';
import { AppContext } from '../pages/AppContext';
import ColorWheel from './ColorWheel';

const ColorSelector = () => {
  const { color, setColor, glassColor, setGlassColor } = useContext(AppContext);
  
  const meshColors = ["#EB5353","#F9D923", "#36AE7C", "#187498", "#363636", "#ffffff","#9656a2","#f79b39"];
  const glassColors = ["#C70039", "#F37121", "#C0E218", "#4c4787", "#202020", "#cdcdcd", "#9327a0", "#915523"]; // Add desired glass color presets

  const [meshPlaceholder, setMeshPlaceholder] = useState("#30c0f5");
  const [glassPlaceholder, setGlassPlaceholder] = useState("#ffffff");
  const [isColorWheelOpen, setIsColorWheelOpen] = useState(false);
  const [isGlassColorWheelOpen, setIsGlassColorWheelOpen] = useState(false);

  // State for input validation
  const [isMeshColorValid, setIsMeshColorValid] = useState(true);
  const [isGlassColorValid, setIsGlassColorValid] = useState(true);

  // Helper Functions
  const isValidHex = (hex) => /^#[0-9A-F]{6}$/i.test(hex);

  const getContrastingTextColor = (hex) => {
    const sanitizedHex = hex.replace('#', '');
    const r = parseInt(sanitizedHex.substr(0, 2), 16);
    const g = parseInt(sanitizedHex.substr(2, 2), 16);
    const b = parseInt(sanitizedHex.substr(4, 2), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  };

  const handleMeshColorChange = (newColor) => {
    const valid = isValidHex(newColor);
    setIsMeshColorValid(valid || newColor === '');
    setColor(newColor);
    console.log("Mesh COLOR SET TO: " + newColor);
    setMeshPlaceholder(newColor);
    if (typeof window.setMeshColor === "function") {
      window.setMeshColor(newColor);
    }
  };

  const handleGlassColorChange = (newColor) => {
    const valid = isValidHex(newColor);
    setIsGlassColorValid(valid || newColor === '');
    setGlassColor(newColor);
    console.log("Glass COLOR SET TO: " + newColor);
    setGlassPlaceholder(newColor);
    if (typeof window.setGlassColor === "function") {
      window.setGlassColor(newColor);
    }
  };

  const handleCustomMeshColorSubmit = (customColor) => {
    if (isValidHex(customColor)) {
      handleMeshColorChange(customColor);
    } else {
      console.error("Invalid hex color format for mesh color.");
    }
  };

  const handleCustomGlassColorSubmit = (customColor) => {
    if (isValidHex(customColor)) {
      handleGlassColorChange(customColor);
    } else {
      console.error("Invalid hex color format for glass color.");
    }
  };

  const handleMeshKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleCustomMeshColorSubmit(color);
    }
  };

  const handleGlassKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleCustomGlassColorSubmit(glassColor);
    }
  };

  return (
    <div className="color-selector">
      {/* Mesh Color Section */}
      <div className="color-section">
        <div className="custom-color-input">
          <button onClick={() => setIsColorWheelOpen(!isColorWheelOpen)}>
            🎨 Armor Color
          </button>
          <input
            className={`color-input-width ${!isMeshColorValid ? 'invalid' : ''}`}
            maxLength="7"
            type="text"
            value={color}
            onChange={(e) => handleMeshColorChange(e.target.value)}
            onKeyPress={handleMeshKeyPress}
            placeholder={meshPlaceholder}
            style={
              isValidHex(color)
                ? {
                    backgroundColor: color,
                    color: getContrastingTextColor(color),
                  }
                : {}
            }
          />
        </div>
        <div className="color-presets">
          {meshColors.map((presetColor, index) => (
            <button
              key={index}
              className="color-button"
              style={{ backgroundColor: presetColor }}
              onClick={() => handleMeshColorChange(presetColor)}
              aria-label={`Set mesh color to ${presetColor}`}
            />
          ))}
        </div>
      </div>

      {/* Glass Color Section */}
      <div className="color-section">
        <div className="custom-color-input">
          <button onClick={() => setIsGlassColorWheelOpen(!isGlassColorWheelOpen)}>
            🎨 Glass Color
          </button>
          <input
            className={`color-input-width ${!isGlassColorValid ? 'invalid' : ''}`}
            maxLength="7"
            type="text"
            value={glassColor}
            onChange={(e) => handleGlassColorChange(e.target.value)}
            onKeyPress={handleGlassKeyPress}
            placeholder={glassPlaceholder}
            style={
              isValidHex(glassColor)
                ? {
                    backgroundColor: glassColor,
                    color: getContrastingTextColor(glassColor),
                  }
                : {}
            }
          />
        </div>
        <div className="color-presets">
          {glassColors.map((presetColor, index) => (
            <button
              key={index}
              className="color-button"
              style={{ backgroundColor: presetColor }}
              onClick={() => handleGlassColorChange(presetColor)}
              aria-label={`Set glass color to ${presetColor}`}
            />
          ))}
        </div>
      </div>

      {/* Color Wheels */}
      {isColorWheelOpen && (
        <ColorWheel
          color={color}
          title={"Armor Color"}
          onChange={handleMeshColorChange}
          onClose={() => setIsColorWheelOpen(false)}
        />
      )}

      {isGlassColorWheelOpen && (
        <ColorWheel
          color={glassColor}
          title={"Glass Color"}
          onChange={handleGlassColorChange}
          onClose={() => setIsGlassColorWheelOpen(false)}
        />
      )}
    </div>
  );
};

export default ColorSelector;
