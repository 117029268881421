// src/components/ColorWheel.js
import React from 'react';
import { HexColorPicker } from 'react-colorful';
import '../css/ColorWheel.css'; // Ensure this CSS file exists for your custom styles

const ColorWheel = ({ color, title, onChange, onClose }) => {
  // Function to generate a random hex color
  const getRandomColor = () => {
    // Generate a random integer between 0 and 16777215 (0xFFFFFF)
    const randomInt = Math.floor(Math.random() * 16777215);
    // Convert the integer to a hex string and pad with zeros if necessary
    const randomColor = `#${randomInt.toString(16).padStart(6, '0')}`;
    return randomColor;
  };

  // Handler for the Random Color button
  const handleRandomColor = () => {
    const newColor = getRandomColor();
    onChange(newColor);
  };

  return (
    <div className="color-wheel-overlay">
      <div className="color-wheel-container">
        {/* Random Color Button */}
        <div className='colorWheelTitle'>{title}</div>
        <button className="random-color-button" onClick={handleRandomColor}>
          Surprise Me
        </button>
        <button className="close-buttoncw" onClick={onClose}>
          &times;
        </button>
        <HexColorPicker color={color} onChange={onChange} />


      </div>
    </div>
  );
};

export default ColorWheel;
