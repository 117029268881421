import React, { createContext, useContext, useState } from 'react';

// Create a context object
const AppContext = createContext();

// Define a custom hook for easier consumption of the context
export function useAppContext() {
  return useContext(AppContext);
}

// Define the provider component
export const AppProvider = ({ children }) => {
  // User-related state
  const [user, setUser] = useState(null);
  const [userUid, setUserUid] = useState(null);
  const [userData, setUserData] = useState(null);

  //app.js
  const [poseToggle, setPoseToggle] = useState(false);
  //const [currentCategory, setCurrentCategory] = useState('');
  const [avatarName, setAvatarName] = useState('');
  const [welcome, setWelcome] = useState(true);
  const [popup, setPopup] = useState(false);
  const [loadedMeshes, setLoadedMeshes] = useState({});
  const [partLoading, setPartLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [screenshot, setScreenshot] = useState(false);
  const [animate, setAnimate] = useState(false);
  // Inventory state
  const [inventory, setInventory] = useState([]);
  //Factory Menu
  const [currentCategory, setCurrentCategory] = useState('initialCategory');
  const [setIsLeft, isLeft] = useState([])

  const updateCategory = (newCategory) => {
    console.log("Updating category to:", newCategory); // Debug log
    setCurrentCategory(newCategory);
  };

  const updateMeshes = (newMeshes) => setLoadedMeshes((prevMeshes) => ({
    ...prevMeshes,
    ...newMeshes,
  }));

  // Application-specific states from `Main.js`
  const [library, setLibrary] = useState([]);
  const [bag, setBag] = useState([]);
  const [category, setCategory] = useState(''); //Factory uses
  //const [currentCategory, setCurrentCategory] = useState('');//Factory uses
  //const [animate, setAnimate] = useState(false);
  //const [avatarName, setAvatarName] = useState('');//Factory uses
  const [activeNavItem, setActiveNavItem] = useState('1');
  const [active, setActive] = useState(false);
  const [games, setGames] = useState([]);
  const [coins, setCoins] = useState([]);
  const [cred, setCred] = useState('0');
  const [credits, setCredits] = useState('0');
  //header.jsx
  const [userImg, setUserImg] = useState(['https://citizenzero.s3.us-west-1.amazonaws.com/icons/UI/defaultUser.png']);
  const [defaultUserImg] = useState(['https://citizenzero.s3.us-west-1.amazonaws.com/icons/UI/defaultUser.png']);
  const [userName, setUserName] = useState(['Not Logged In'])
  // Method to update credits, ensure it correctly updates the state
  const updateCredits = (newCredits) => setCred(newCredits);

  //Mesh Color Selection
  const [color, setColor] = useState('#000000');
  const [glassColor, setGlassColor] = useState("#ffffff");

  // Organize context value for clarity
  const contextValue = {
    // User related
    user, setUser,
    userUid, setUserUid,
    userData, setUserData,
    //app.js
    poseToggle, setPoseToggle,
    currentCategory, setCurrentCategory,
    avatarName, setAvatarName,
    welcome, setWelcome,
    popup, setPopup,
    loadedMeshes, updateMeshes, // Use the custom updater function for complex state
    partLoading, setPartLoading,
    message, setMessage,
    screenshot, setScreenshot,
    animate, setAnimate,

    // Inventory
    inventory, setInventory,

    // From `Main.js`
    library, setLibrary,
    bag, setBag,
    category, setCategory,
    //currentCategory, setCurrentCategory,
    //animate, setAnimate,
    //avatarName, setAvatarName,
    activeNavItem, setActiveNavItem,
    active, setActive,
    games, setGames,
    coins, setCoins,
    cred, updateCredits,
    setCredits, credits,

      //Mesh Colors
    color, setColor,
    glassColor, setGlassColor,
    //factory menu
    updateCategory,
    //header.jsx
    userImg, setUserImg, defaultUserImg,
    userName, setUserName,
    setIsLeft, isLeft,
  };

  // Provide context to child components
  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export { AppContext };
