import React, { useState, useEffect, useContext, useRef } from 'react';
import './itemCardSCSS.scss';
import Confirmation from '../modal/ConfirmPurchase/Confirmation';
import NoUser from '../modal/noUser/NoUser';
import { AppContext } from '../../pages/AppContext';

function ItemCard({ saleItem, updateCredits }) {
  const { inventory, setInventory, userUid } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [isItemOwned, setIsItemOwned] = useState(false);

  // Add refs and state for 3D effect
  const cardRef = useRef(null);
  const [tiltStyle, setTiltStyle] = useState({});
  const styleTag = useRef(null);

  useEffect(() => {
    const checkItemOwnership = async () => {
      if (userUid && saleItem) {
        try {
          const url = `https://czbackend.herokuapp.com/api/CZMarket/checkItemInInventory?userId=${userUid}&itemId=${saleItem.item_id}&itemType=${saleItem.category}`;
          const response = await fetch(url);
          const result = await response.json();
          setIsItemOwned(result.isOwned);
        } catch (error) {
          console.error('Failed to check item ownership:', error);
        }
      } else {
        setIsItemOwned(false);
      }
    };

    checkItemOwnership();
  }, [userUid, saleItem]);

  const handleMouseMove = (e) => {
    const card = cardRef.current;
    const cardRect = card.getBoundingClientRect();

    const centerX = cardRect.left + cardRect.width / 2;
    const centerY = cardRect.top + cardRect.height / 2;

    const mouseX = e.clientX - centerX;
    const mouseY = e.clientY - centerY;

    // Calculate tilt angles
    const rotateX = (mouseY / cardRect.height) * 30; // Adjust for more/less tilt
    const rotateY = -(mouseX / cardRect.width) * 30; // Adjust for more/less tilt

    setTiltStyle({
      transform: `translateX(-50%) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
    });

    // Holographic effect based on mouse position
    const px = Math.abs(Math.floor((100 / cardRect.width) * mouseX) - 100);
    const py = Math.abs(Math.floor((100 / cardRect.height) * mouseY) - 100);
    const lp = 0 + (px - 50) / 1.6;
    const tp = 0 + (py - 50) / 1.6;
    const px_spark = 50 + (px - 50) / 7;
    const py_spark = 50 + (py - 50) / 7;
    const p_opc = 20 + Math.abs((50 - px) + (50 - py)) * 1.5;

    // Set dynamic styles for :before and :after pseudo-elements
    const styleContent = `
      .itemCard:hover::before {
        background-position: ${lp}% ${tp}%;
      }
      .itemCard:hover::after {
        background-position: ${px_spark}% ${py_spark}%;
        opacity: ${p_opc / 100};
      }
    `;
    styleTag.current.innerHTML = styleContent;
  };

  const handleMouseLeave = () => {
    setTiltStyle({
      transform: `translateX(-50%) rotateX(0deg) rotateY(0deg)`,
      transition: 'transform 0.5s ease',
    });

    // Clear the styles when mouse leaves
    styleTag.current.innerHTML = "";
  };

  const handleConfirm = async (itemId) => {
    if (userUid) {
      try {
        const response = await fetch('https://czbackend.herokuapp.com/api/CZMarket/addItemToInventory', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userUid,
            itemId: itemId,
            itemType: saleItem.category,
          }),
        });

        const result = await response.json();
        if (result.success) {
          setInventory([...inventory, saleItem]);
          updateCredits(result.credits);
          alert('Item added successfully!');
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.error('Failed to add item to inventory:', error);
        alert('Error adding item to inventory.');
      }
    }
    closeModal();
  };

  const handleAddToInventory = () => {
    setInventory([...inventory, saleItem]);
    closeModal();
  };

  const handleRemoveFromInventory = () => {
    setInventory(inventory.filter(invItem => invItem.item_id !== saleItem.item_id));
    closeModal();
  };

  const openModal = (content) => {
    setIsModalOpen(true);
    setModalContent(content);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getModalContent = () => {
    if (userUid === null) {
      console.log('User is not logged in.');
      return <NoUser onClose={closeModal} />;
    } else {
      return (
        <Confirmation
          onClose={closeModal}
          onConfirm={() => handleConfirm(saleItem.item_id)}
          itemId={saleItem.item_id}
          saleItem={saleItem}
        />
      );
    }
  };

  return (
    <>
      <style ref={styleTag}></style> {/* Dynamically updates styles for :before and :after */}
      <div className="col">
        <div className="itemContainer" ref={cardRef}>
          <div
            className="itemCard"
            onClick={(e) => {
              if (isItemOwned) {
                e.preventDefault();
                console.log('Item is already owned.');
                return;
              }
              e.preventDefault();
              openModal(getModalContent());
            }}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={tiltStyle}
          >
            <div className="itemFeature">{saleItem.category}</div>
            <div className="imageWrapper">
              <img
                src={`https://citizenzero.s3.us-west-1.amazonaws.com/thumbs/${saleItem.category}/${saleItem.item_name}.png`}
                alt={saleItem.item_name}
                className="img-fluid"
              />
            </div>
            <div className="itemDetails">
              <h4 className="itemTitle">{saleItem.item_name}</h4>
            </div>
            <span
              className={`itemPrice ${isItemOwned ? 'itemPriceOwned' : ''} ${
                saleItem.discount_amount > 0 ? 'itemPriceDiscounted' : ''
              }`}
            >
              {isItemOwned ? 'ACQUIRED' : (saleItem.price - saleItem.discount_amount).toFixed(0)}
            </span>
          </div>
        </div>
        {isModalOpen && modalContent}
      </div>
    </>
  );
}

export default ItemCard;
